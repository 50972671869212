import numeral from 'numeral';
import dayjs from 'dayjs';

class Helper{
	#timer = {};

	constructor(){}
	get hash(){
		return this.createHash(8);
	}

	/** Генератор хеш-кода */
	createHash(length) {
		const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
		let text = '';

		length = length || 6;

		for (let i = 0; i < length; i++) {
			text += chars.charAt(Math.floor(Math.random() * chars.length));
		}

		return text;
	}

	rndNum(a, b){
		if(a && b) return Math.floor(Math.random() * (b - a + 1)) + a;
		else if(a) return Math.floor(Math.random() * a);
	}

	rndItem(array){
		return array[this.rndNum(array.length)];
	}

	/** Фильтр часто запускаемых действий */
	debounce({ keyTimer, duration=10, action }){
		if(this.#timer[keyTimer]){
			clearTimeout(this.#timer[keyTimer]);
			this.#timer[keyTimer] = null;
		}

		if(action){
			this.#timer[keyTimer] = setTimeout(() => {
				action();
				$(window).off(`visibilitychange.timer_${keyTimer}`);
			}, duration);
		}

		// Если браузер в момент ожидания был свернут или был переход на другую вкладку
		if(this.#timer[keyTimer]) $(window).off(`visibilitychange.timer_${keyTimer}`).on(`visibilitychange.timer_${keyTimer}`, ()=>{
			// console.log(document.visibilityState);
			if(document.visibilityState=='visible'){
				clearTimeout(this.#timer[keyTimer]);
				if(action) action();
				$(window).off(`visibilitychange.timer_${keyTimer}`);
			}
		});
	}

	/** Среднее целое */
	getMidSize(min,max){
		return Math.ceil((max + min)/2);
	}

	/**
	 * Преобразовывает строку/число в число формата 0.[00]
	 * @param {string|number} num
	 */
	getNum(num) {
		return Number(numeral(num).format('0,0.[00]').replace(/,/g,''));
	}

	/**
	 * Преобразовывает строку/число в денежный формат
	 * @param {string|number} num
	 */
	getMoney(num){
		return numeral(num).format('0,0.[00]').replace(/,/g,' ').replace(/\./g,',');
	}

	/**
	 * Преобразовывает строку/число в число c запятой формата 0,[00]
	 * @param {string|number} num
	 */
	getFormatNum(num){
		// console.log(num);
		return numeral(num).format('0.[00]').replace(/\./g,',');
	}

	/** Фильтр ввода только чисел */
	inputDigits(e){
		let regex = /^\d*(\.\d{0,1})?$/;
		let num = e.target.value;
		let isNum = !(e.key != 'Enter' && regex.test(e.key) && regex.test(num) && (Number(num) || Number(num)==0));

		if(isNum) {
			e.preventDefault();
		}
	}

	/** сравнение двух массивов */
	hasEqualArrays(a, b) {
		return JSON.stringify(a)==JSON.stringify(b);
	}
	equalArrays(arrA, arrB, key) {
		if(key) return arrA.filter(itemA => !arrB.some(itemB => itemA[key] === itemB[key]));
		else return arrA.filter(item=>!arrB.includes(item));
	}

	/** перемешивание массива */
	shuffle(array) {
		const tmpArr = [...array];

		for (let i = tmpArr.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1)); // Генерация случайного индекса
			[tmpArr[i], tmpArr[j]] = [tmpArr[j], tmpArr[i]]; // Обмен элементов
		}

		return tmpArr;
	}

	pause(duration=500, call){
		return new Promise((resolve)=>{
			const timer = setTimeout(() => {
				if(call) call();
				$(window).off(`visibilitychange.timer_${timer}`);
				resolve(true);
			}, duration);

			// Если браузер в момент ожидания был свернут или переход на другую вкладку
			$(window).off(`visibilitychange.timer_${timer}`).on(`visibilitychange.timer_${timer}`, ()=>{
				// console.log(document.visibilityState);
				if(document.visibilityState=='visible'){
					clearTimeout(timer);
					if(call) call();
					resolve(true);
					$(window).off(`visibilitychange.timer_${timer}`);
				}
			});
		});
	}

	clone(obj){
		return JSON.parse(JSON.stringify(obj));
	}

	getQueueItem(arr){
		let item = arr.shift();
		arr.push(item);

		return item;
	}

	/** Трансформация строки из camelCase в kebab-case */
	camelToKebab(str) {
		return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
	}

	camelToSnake(str) {
		return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
	}

	/** Первая буква строки в верхнем регистре */
	upFirstLetter(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	roundToTwo(num){
		return +(Math.round(num + "e+2") + "e-2");
	}

	copyTextBuffer(text){
		return navigator.clipboard.writeText(text).then(()=>{
			console.log('скопировано в буфер');
			return true;
		})
	}

	splitIntoMonthlyPeriods(startDate, endDate) {
		const periods = {};
		let start = new Date(startDate);
		let currentDate = new Date(startDate);
		const lastDate = new Date(endDate);

		while (currentDate <= lastDate) {
			const keyCurMonth = dayjs(currentDate).format('YYYY-MM');
			const keyStartMont = dayjs(start).format('YYYY-MM');
			const curDay = dayjs(currentDate).date();

			if(keyCurMonth != keyStartMont) start = currentDate;

			const from = dayjs(start).format('YYYY-MM-DD');
			const to = dayjs(currentDate).format('YYYY-MM-DD');
			periods[keyCurMonth] = [[from, to]];

			currentDate = dayjs(currentDate).date(curDay+1).toDate();
		}

		return periods;
	}

	convertDatesToPeriods(arrDates=[]){
		const dates = [...arrDates].sort((a, b) => a - b);
		const result = [];
		let currentPeriod = [dates[0]];

		if(!dates?.length) return result;

		for (let i = 1; i < dates.length; i++) {
			const diffTime = Math.abs(dates[i] - dates[i - 1]);
			const diffDays = diffTime / (1000 * 60 * 60 * 24);

			if (diffDays <= 1) {
				currentPeriod[1] = dates[i]; // Обновляем конечную дату
			} else {
				let startDate = currentPeriod[0];
				let endDate = currentPeriod[1];
				if(endDate===undefined) endDate = startDate;

				result.push([
					dayjs(startDate).format('YYYY-MM-DD'),
					dayjs(endDate).format('YYYY-MM-DD')
				]);
				currentPeriod = [dates[i]];
			}
		}

		let startDate = currentPeriod[0];
		let endDate = currentPeriod[1];
		if(endDate===undefined) endDate = startDate;

		result.push([
			dayjs(startDate).format('YYYY-MM-DD'),
			dayjs(endDate).format('YYYY-MM-DD')
		]);

		return result;
	}

	/** Сравнение оригинального массива и измененного на добавленные и удаленные элементы из оригинального */
	compareArrays(originalArray, modifiedArray) {
		const addedElements = modifiedArray.filter(item => !originalArray.includes(item));
		const removedElements = originalArray.filter(item => !modifiedArray.includes(item));

		return [addedElements, removedElements];
	}

	/** Расстояние между дочкой и родителем по левому краю */
	getDistanceFromParentLeft(parentElement, childElement) {
		let parentLeft = parentElement.offsetLeft;
		let childLeft = 0;

		while (childElement && childElement !== parentElement) {
			childLeft += childElement.offsetLeft;
			childElement = childElement.offsetParent;
		}

		return childLeft - parentLeft;
	}

	createLink({ link, filename }={}){
		// Создание ссылки для скачивания изображения
		const elLink = document.createElement('a');
		elLink.href = link;
		if(filename) elLink.download = filename;

		// Создание события клика на ссылке и имитация клика
		document.body.appendChild(elLink);
		elLink.click();
		// console.log({elLink});
		document.body.removeChild(elLink);
	}

	async downloadFile({ url, nameWithFileType = null, data = null } = {}) {
		let base = '/api/';
		let headers = {};

		if(['localhost'].includes(window.location.hostname)){
			base = 'https://outplan-dev.devtestprod.ru/api/';
			// this.base = 'https://outplan-test.devtestprod.ru/api/';
			headers['x-dev-token'] = 'dev_rim_erp' // dev - разработческий токен
		}

	
		let body = null;
		if (data) {
			// Если есть файл, используем FormData
			body = new FormData();
			for (const key in data) {
				body.append(key, data[key]);
			}
		}
	
		return fetch(base + url, {
			method: data ? 'POST' : 'GET',
			headers: {
				...headers,
				...(data ? {} : { 'Content-Type': 'application/json' }), // Убираем Content-Type для FormData
			},
			body: data ? body : null,
		}).then(async (response) => {
				let filename = nameWithFileType

				if (!response.ok) {
					console.error(`Ошибка при загрузке: ${response.statusText}`);
					return;
				}

				if (!filename) {
					const contentDisposition = response.headers.get('Content-Disposition');
					if (contentDisposition) {
						const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
						if (fileNameMatch && fileNameMatch[1]) {
							filename = fileNameMatch[1];
						}
					}
				}
	
				if (!filename) {
					console.error('Не удалось извлечь имя файла');
					return;
				}
				const blob = await response.blob();

				if (!blob || blob.size === 0) {
					console.error('Получен пустой файл');
					return;
				}
				console.log('res>', response)
				console.log('blob>', blob)

				// Создание URL-адреса ресурса
				const blobURL = URL.createObjectURL(blob);

				this.createLink({ link: blobURL, filename });

				// Освобождение ресурсов URL-адреса ресурса
				URL.revokeObjectURL(blobURL);

				return true;
			}).catch(error => {
				console.error('Не удалось скачать ресурс:', error);
				return;
			});
	}

	cleanHtmlString(input) {
		// Удаляем HTML-теги
		const noHtml = input.replace(/<[^>]*>/g, '');
		// Удаляем лишние пробелы
		const cleaned = noHtml.replace(/\s+/g, ' ').trim();
		return cleaned;
	}
}

export default new Helper();
