import { mapState, mapActions, mapWritableState } from 'pinia';
import { useNotifStore } from '@/app/stores/notification/notif-store';

import { QueueList } from '@/app/utils/queue-list';
import { colorList } from '@/app/components/notification/notif-colors-data';

import { ElPopover, ElMessageBox, ElBadge } from "element-plus";

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BtnHeadNotif from "@/app/components/controls/buttons/btn-head-notif/BtnHeadNotif.vue";
import NotifMessageManager from "@/app/components/header/notif-feed/message-types/notif-message-manager/NotifMessageManager.vue";
import NotifMessageSystem from '@/app/components/header/notif-feed/message-types/notif-message-system/NotifMessageSystem.vue';
import api from '@/app/utils/service-api';

const colorQueue = new QueueList({
	list: colorList,
});

export default {
	name: 'NotifFeed',
	components: {
		ElPopover, ElBadge,
		SvgIcon, BtnHeadNotif,
		NotifMessageManager, NotifMessageSystem,
	},
	data() {
		return {
			isVisiblePopover: false,
		}
	},
	computed: {
		...mapState(useNotifStore, ['notifData', 'unreadCount']),

		notifList(){
			const list = [];

			for(const item of this.notifData){
				if(item?.senderType=='user'){
					list.push({
						...item,
						colorData: colorQueue.getItem(),
					});
					continue;
				}
				list.push(item);
			}
			// console.log(list);

			return list;
		},

		countNotRead(){
			return this.unreadCount
		}
	},
	methods: {
		...mapActions( useNotifStore, ['getUnreadMessages', 'setNotifData']),

		async setAllMessagesRead(notificationsIds){
			try {
				const res = await api.postData({
					model: `notifications-center/read`,
					data: { notificationsIds }
				});

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
		},


		async clickAllCheck(){
			ElMessageBox.confirm(null, null, {
				title: 'Отметить все как прочитанные?',
				// message: 'Вы точно хотите применить изменения?',
				showClose: false,
				customClass: 'bs-message-box',
				// type: 'warning',
			}).then(async() => {
				const notificationsIds = this.notifData.map(item => item.id);
				const res = await this.setAllMessagesRead(notificationsIds)
				this.setNotifData(res)
			}).catch(() => {
				console.log('Отмена');
			})
		},
	},
	created(){
	},
	watch: {
		isVisiblePopover(value) {
			if (value) {
				// Согласование - восстановить
				this.getUnreadMessages()
				console.log('dada');
			}
		},
	},
}